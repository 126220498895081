import { Component, OnInit, ApplicationRef, Renderer2, ElementRef, HostListener, OnDestroy, ViewChildren, QueryList, AfterViewChecked, DoCheck, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Game } from '../classes/Game';
import { Question } from '../classes/QSet';
import { Team } from '../classes/Team';
import { User } from '../classes/User';
import { GameService } from '../services/game.service';
import { AuthService } from '../services/auth.service';
import { RecapService } from '../services/recap.service';
import { ShiftService } from '../services/shift.service';
import { LocalTimeService } from '../services/local-time.service';
import { CorrectAnswersPipe } from '../correct-answers.pipe';
import { environment } from '../../environments/environment';
import { RoundComponent } from '../round/round.component';
import { TeamDetailComponent } from '../team-detail/team-detail.component';
import { finalize } from 'rxjs/operators';
import { DateDisplayEditComponent } from '../date-display-edit/date-display-edit.component';

@Component({
  selector: 'app-live-game',
  templateUrl: './live-game.component.html',
  styleUrls: ['./live-game.component.css']
})
export class LiveGameComponent implements OnInit, AfterViewChecked, DoCheck, OnDestroy {
	@ViewChild('contentDiv', { static: true }) contentDiv: ElementRef;
	@ViewChildren('rlist') rounds: QueryList<RoundComponent>;
	@ViewChildren('tlist') teams: QueryList<TeamDetailComponent>;
	@ViewChild('adjustableDiv', { static: false }) adjustableDiv: ElementRef;
	@HostListener('window:resize', ['$event'])

	game: Game;
	user: User;
	shift: Shift;
	lastRound: RoundComponent;
	gameid: string;
	loading = "Loading Game State...";
	err = "";
	activeModal = "";
	teamDetailIdx: number;

	expandIdx: number;

	recapMode: boolean;

	wsAttempts = 0;
	deadWS: boolean = null;
	ws: WebSocket;
	heartbeat;

	buttonContext = "Select Next Round";
	showContextMenu: boolean = true;

	answerPipe = new CorrectAnswersPipe();

	scoringQuestion = 0;

	recapErrors: string[] = [];
	updatingRecap: boolean;
	recapUpdatePending: boolean;
	workingStart: LocalTime;
	workingEnd: LocalTime;
	overHours: boolean;
	minsWorked: number;
	hoursWorked: number;
  retryButtonLink: string | null = null;
  showSet: boolean = true

	gameInfoSlug: string = ""

	externalCredentials: {email: string, password: string};

	messageLog: string[] = [];

	constructor(private route: ActivatedRoute,
				private router: Router,
				private gameServe: GameService,
				private recapServe: RecapService,
				private shiftServe: ShiftService,
				private authService: AuthService,
				private timeServe: LocalTimeService,
				private appRef: ApplicationRef,
				private renderer: Renderer2) { }

	ngOnInit(): void {
		this.route.params.subscribe((p: Params) => {
			this.gameid = p.id;
			this.authService.getHostDetail()
				.subscribe(
					(res:User) => {
						this.user = res;
						localStorage.setItem("user_detail", JSON.stringify(res))
						this.getMyShift();
						this.connectWS();
					},
					err => console.log(err))
			this.setVisibilityListener();
		});

    // reset the retry button link
    this.retryButtonLink = null;
    this.showSet = true;
	}

	getLicense() {
		this.gameServe.getLicense(this.game.event_id)
			.subscribe((res: {email: string, password: string}) => {
				console.log(res);
				this.externalCredentials = res;
			})
	}

	ngDoCheck() {
		if (this.rounds?.length) {
			this.setLastRound();
			this.updateButtonContext();
		}
	}

	ngAfterViewChecked() {
		if (!this.loading && this.game) {
			this.game.needsReview = this.unreviewedRounds();
			this.updateElementHeight();
		}
	}

	ngOnDestroy() {
		console.log("closing connection", this.ws);
		this.deadWS = true;
		this.ws.close();
		if (this.heartbeat) {
			clearInterval(this.heartbeat);
		}
	}

	setLastRound() {
		let round;
		for (let r of this.rounds) {
			if (r.round.round_id === this.game.last_round) {
				round = r;
			}
		}
		this.lastRound = round;
	}

	setExpanded($event: number) {
		this.expandIdx = $event;
	}

	roundStatus(round_id: number) {
		if (!this.game.last_round) return "---";
		if (!round_id) return "Submissions Closed";
		else if (!this.game.askedThisRound(round_id)) return "Introducing Round";
		else if (!this.game.submissions_open) return "Reading Questions";
		else  {
			return `Submissions Open (${this.game.remainingSubmits(round_id)}/${this.game.teams.length})`;
		}
	}

	roundSummary() {
		if (this.lastRound) this.lastRound.activeModal = 'submitList';
	}

	getButtonContext() {
		if (!this.lastRound) return "No Active Round";
		if (this.game.round_index) {
			if (!this.game.submissions_open) {
				if (this.lastRound.numAsked() < this.lastRound.round.num_questions) {
					if (this.lastRound.round.handout) return "Release All Questions";
					return (this.lastRound.round.single_answer) ? "Next Clue" : "Next Question";
				}
				else if (this.lastRound.round.single_answer) return "Close Submissions";
				else return "Open Submissions";
			}
			else return "Close Submissions";
		}
		else return "No Active Round";
	}

	buttonContextGreen() {
		if (this.buttonContext === "Close Submissions" || this.buttonContext === "No Active Round") return false;
		else return true;
	}

	handleButtonContext() {
		switch (this.buttonContext) {
			case 'No Active Round':
				break;

			case 'Next Question':
				this.expandIdx = this.lastRound.round.round_id;
				this.lastRound.queueNextAnswer();
				break;

			case 'Next Clue':
				this.expandIdx = this.lastRound.round.round_id;
				this.lastRound.queueNextAnswer();
				break;

			default:
				this.lastRound.toggleSubmissions();
				break;
		}
	}

	roundAnswers() {
		if (this.lastRound) {
			this.expandIdx = this.lastRound.round.round_id;
			this.lastRound.queueUnreviewedAnswer();
		}
	}

	updateButtonContext() {
		let context = this.getButtonContext();
		this.buttonContext = context;
	}

	unreviewedRounds() {
		let rounds = [];
		for (let r of this.rounds) {
			if (r.needsReview > 0) {
				rounds.push(r);
			}
		}
		return rounds;
	}

	toRecap() {
		this.recapMode = true;
	}

	getGameState(gameid: string) {
		this.loading = "Loading Game State...";
		this.err = "";
		let obs = this.gameServe.getGameState(gameid)
			.subscribe((res: any) => {
				this.game = new Game(res);
				this.showSet = true;
				this.gameInfoSlug = `${this.game.niceTitle()} at ${this.game.venue}`
				if (this.game.use_external_app && !this.externalCredentials) {
					this.getLicense();
				}
				this.loading = "";
				this.appRef.tick();
			},
			err => {
				this.err = err.error.detail;
				this.loading = "";
			})
	}

	setVisibilityListener() {
		document.addEventListener('visibilitychange', (event) => {
			console.log(document.visibilityState);
			if (document.visibilityState === 'visible') {
				if (!this.ws || this.ws.readyState === WebSocket.CLOSED) {
					this.deadWS = true;
					this.err = "Can't connect to websocket.";
				}
			}
		})
	}

	timeWorked() {
		let start = this.timeServe.localTimeToIso8601(this.workingStart);
		let end = this.timeServe.localTimeToIso8601(this.workingEnd);
		if (!start || !end) return "--:--";
		let workingTimes = [
			new Date(start).valueOf(),
			new Date(end).valueOf()
		]
		workingTimes.sort((a, b) => {
			if (a > b) return 1;
			else if (a < b) return -1;
			else return 0;
		})
		this.minsWorked = Math.floor((workingTimes[1] - workingTimes[0]) / 1000 / 60);
		this.hoursWorked = Math.floor(this.minsWorked / 60);
		let mins = this.minsWorked % 60;
		let lead = (mins < 10) ? '0' : '';
		return `${this.hoursWorked}:${lead}${mins}`;
	}

	resetConnection() {
    if(!this.retryButtonLink){
      this.err = "Attempting to reconnect";
      this.deadWS = null;
      this.wsAttempts = 0;
      this.connectWS();
    }else{
      this.router.navigate([this.retryButtonLink]);
    }
	}

	sendHeartbeat() {
		if (this.ws) {
			this.ws.send("thump thump")
		}
		else console.log("No websocket available for sending heartbeat")
	}

	acknowledgeMsg(stream: string, msg_id: string) {
		if (this.ws) {
			if (!this.messageLog.includes(msg_id)) {
				this.messageLog.push(msg_id)
				if (this.messageLog.length > 15) this.messageLog.shift()
			}
			this.ws.send(`acknowledge|${msg_id}`)
		}
		else console.log("No websocket available for sending acknowledgement")
	}

	connectWS() {
		if (this.ws) this.ws.close();
		this.messageLog = [];

		const protocol = environment.ws.protocol;
		let host = (!environment.api_domain) ? window.location.host : environment.api_domain;
		let client_id = localStorage.getItem('kt_client_id');
		this.ws = new WebSocket(`${protocol}://${host}/api/game/ws/${this.gameid}/host/${client_id}`);

		this.ws.onerror = (event) => {
			console.log('WS error:', event);
			if (this.wsAttempts < 5 && !this.recapMode) {
				this.err = "Attempting to reconnect";
				this.wsAttempts++;
				this.connectWS();
			}
			else {
				this.err = "Unable to connect to server."
				this.deadWS = true;
			}
		};

		this.ws.onclose = (event) => {
			if (this.deadWS === null) {
				this.deadWS = false;
				this.ws.onerror(event);
			}
		}

		this.ws.onopen = (event) => {
			const token = this.authService.getToken();
			console.log('WS opened:', event);
			this.retryButtonLink = "";
			this.err = "";
			this.deadWS = null;
      if(token){
        let msg = {type: "HOST_VERIFY", token: token};
			  this.ws.send(JSON.stringify(msg));
      }
			this.getGameState(this.gameid);
			if (!this.heartbeat) this.heartbeat = setInterval(this.sendHeartbeat.bind(this), 150000)
		};

		this.ws.onmessage = (event) => {
			let msg = JSON.parse(event.data);
			let data:any = {};
      if( msg.hasOwnProperty('data') ){
				data = JSON.parse(msg.data);
			}
			console.log("WS:", data);
      this.retryButtonLink = null;
      this.showSet = true;
      if (!this.messageLog.includes(msg.msg_id)) {
				if (this.messageLog.length === 0 || msg.msg_id > this.messageLog[this.messageLog.length - 1]) {
					this.messageLog.push(msg.msg_id);
					switch (data.type) {

						case "NO_GAME_ACCESS":
							this.deadWS = true;
							this.wsAttempts = 5;
		          this.err = data.error ?? "Server refused access.";
		          this.retryButtonLink = "/";
		          this.showSet = false;
							break;

						case 'NO_AUTH':
							this.deadWS = true;
							this.wsAttempts = 5;
							this.err = "Unable to connect to server";
							break;

						case 'WEBSOCKET_ERROR':
		      		this.deadWS = true;
		      		this.wsAttempts = 5;
		      		this.err = "Connection Interrupted";
		      		this.ws.close();
		      		break;

						case 'ASK':
							for (let q of data.questions) {
								if (!this.game.questionIsAsked(this.game.round_index, q.question_id)) {
									this.game.addAsked(data.round_id, q.question_id);
								}
							}
							if (data.open_submissions) this.game.submissions_open = true;
							if (this.game.round_index && !this.game.submissions_open) {
								let asked = this.game.askedThisRound(this.game.round_index);
								let round = this.game.getRoundById(this.game.round_index);
								if (asked.length >= round.num_questions && !round.single_answer) {
									this.lastRound.activeModal = '';
									this.activeModal = "allQuestionsAsked";
								}
							}
							break;

						case 'RECALL':
							this.game.removeAsked(data.round_id, data.question_id);
							break;

						case 'HOST_JOIN':
							// this.game.addHost(data.host);
							break;

						case 'UPDATE_HOST':
							// this.game.updateHost(data.host);
							break;

						case 'REMOVE_HOST':
							// this.game.removeHost(data.employee_id);
							break;

						case 'PLAYER_JOIN':
							this.game.addTeam(data.team);
							break;

						case 'SUBMIT_ANSWER':
							if (this.game.round_index && this.game.submissions_open) {
								let round_key = 'r_' + data.round_id;
								if (!this.game.answered[round_key]) {
									this.game.answered[round_key] = {};
								}
								let a_data = { answers: data.answered, doubled: data.doubled };
								this.game.answered[round_key]['t_' + data.team_code] = a_data;
								this.lastRound.updateAllReviewed();
								this.appRef.tick();
								this.game.resetter = !this.game.resetter;
								if (this.game.remainingSubmits(this.game.round_index) === this.game.activeTeams()) {
									this.activeModal = "allAnswersIn";
								}
							}
							break;

						case 'ADD_INCORRECT':
							let viewReset = this.game.markWrong(data.value, data.question_id)
							if (viewReset) this.game.resetter = !this.game.resetter;
							break;

						case 'ADD_VARIANT':
							let resetView = this.game.markCorrect(
								data.value,
								data.question_id,
								data.a_idx
							)
							if (resetView) this.game.resetter = !this.game.resetter;
							break;

						case 'ROUND_START':
							this.game.round_index = data.round_index;
							this.game.last_round = data.round_index;
							break;

						case 'ROUND_END':
							this.game.round_index = null;
							break;

						case 'OPEN_SUBMISSIONS':
							this.game.submissions_open = true;
							break;

						case 'CLOSE_SUBMISSIONS':
							this.game.submissions_open = false;
							if (!data.keep_round) this.game.round_index = null;
							let r_key = 'r_' + data.round_id;
							if (data.r_ans && data.r_ans[0]) this.game.answered[r_key] = data.r_ans[0];
							this.lastRound.updateAllReviewed();
							this.appRef.tick();
							this.game.resetter = !this.game.resetter;
							break;

						case 'RESET_SUBMISSION':
							this.game.resetTeamSubmissionForRound(data.round_id, data.kt_team_id);
							this.game.resetter = !this.game.resetter;
							this.appRef.tick();
							break;

						case 'PROMOTE_CAPTAIN':
							let team = this.game.getTeamOfPlayer(data.player_code);
							for (let player of team.players) {
								if (player.player_code === data.player_code) player.is_captain = true;
								else player.is_captain = false;
							}
							break;

						case 'KICK_TEAM':
							if (data.keep_scores) {
								this.game.downgradeTeam(data.team_code);
							}
							else {
								this.game.removeTeam(data.team_code);
							}
							if (this.game.submissions_open) {
								if (this.game.remainingSubmits(this.game.round_index) === this.game.teams.length) {
									this.activeModal = "allAnswersIn";
								}
							}
							break;

						case 'ADD_TEAM':
							this.game.addTeam(data.team);
							break;

						case 'REGISTER_TEAM':
							for (let t of this.game.teams) {
								if (t.team_code === data.team_code) {
									t.kt_team_id = data.kt_team_id
								}
							}
							break;

						case 'NEW_TEAM_NAME':
							for (let t of this.game.teams) {
								if (t.team_code === data.team_code) {
									t.team_name = data.team_name
								}
							}
							break;

						case 'SCORE_SET':
							let r = this.game.answered["r_" + data.round_id];
							if (!r) {
								this.game.answered["r_" + data.round_id] = {};
								r = this.game.answered["r_" + data.round_id];
							}
							let t = r['t_' + data.team_code];
							if (!t) {
								r['t_' + data.team_code] = {};
								t = r['t_' + data.team_code];
							}
							t.score = data.score;
							this.game.resetter = !this.game.resetter;
							break;

						case 'END_GAME':
							this.game.status = 'ended';
							break;

						case 'RELOAD_GAME':
							this.game.status = 'live';
							break;
					}
				}
				else {
					this.loading = "Refreshing Connection...";
					this.connectWS();
					return;
				}
			}
			this.acknowledgeMsg(msg.stream_name, msg.msg_id);
		}
	}

	closeSubmissions() {
		this.closeActiveModal();
		this.gameServe.closeSubmissions(this.game.gameid)
			.subscribe((res: any) => {},
			err => {
				console.log(err);
			})
	}

	openSubmissions() {
		this.activeModal = "";
		this.gameServe.openSubmissions(this.game.gameid)
			.subscribe((res: any) => {}, err => console.log(err));
	}

	closeActiveModal() {
		this.activeModal = '';
		this.err = "";
	}

	changeCode() {
		this.activeModal = "";
		this.err = "";
		this.gameServe.changeCode(this.game.gameid)
			.subscribe((res: any) => {
				this.game.code = res.code;
			},
			err => {
				this.err = "Error: unable to change code";
			})
	}

	reopenGame() {
		if (this.game.status === 'live') return;
		this.gameServe.reopenGame(this.game.gameid)
			.subscribe(
				res => this.getGameState(this.game.gameid),
				err => this.err = err.error.detail)
	}

	getMyShift() {
		this.shiftServe.getMyShift(this.gameid)
			.subscribe(
				(res: {shift: Shift}) => {
					this.shift = res.shift;
					if (!this.user.admin) {
						if (this.shift) {
							if (this.shift.status !== 'live' || this.game.status !== 'live') {
								this.recapMode = true;
							}
						}
					}
				},
				err => this.err = err.error.detail)
	}

	validateEnd(endType: 'shift' | 'game') {
		this.activeModal = "issueCheck";
		this.shiftServe.getGameShifts(this.game.gameid)
			.subscribe(
				(res: Shift[]) => {
					let open = 0;
					for (let s of res) {
						if (s.status === 'live') open++;
						if (s.user.userid === this.user.userid) {
							this.shift = s;
						}
					}
					if (endType === 'shift') {
						let endpoint = "confirmEndGame";
						if (open < 2) {
							endpoint = this.confirmEndGame(true);
						}
						if (endpoint === "confirmEndGame") {
							let cap = `${endType.charAt(0).toUpperCase()}${endType.slice(1)}`;
							this.activeModal = `confirmEnd${cap}`;
						}
						else this.activeModal = endpoint;
					}
					else if (endType === 'game') {
						if (open > 0) this.activeModal = "noEndWithOpenShift";
						else this.confirmEndGame()
					}
				},
				err => {
					this.err = err.error.detail;
					this.activeModal = "";
				})
	}

	confirmEndGame(checkOnly: boolean = false) {
		if (this.game.round_index) {
			if (checkOnly) return "noEndWithRoundOpen";
			else this.activeModal = "noEndWithRoundOpen";
		}
		if (this.game.needsReview.length) {
			if (checkOnly) return "noEndBeforeReview";
			else this.activeModal = "noEndBeforeReview";
		}
		else {
			if (checkOnly) return "confirmEndGame";
			else this.activeModal = "confirmEndGame"
		}
	}

	endShift() {
		let dtDisplay = new DateDisplayEditComponent();
		dtDisplay.setDateTime(new Date());
		let end = dtDisplay.formatOutputString();
		this.loading = "Ending Shift...";
		this.err = "";
		this.activeModal = "";
		this.shiftServe.endShift(this.shift.shiftid, end)
			.subscribe(
				(res: EndShiftResult) => {
					this.shift = res.shift;
					if (!res.live_count) {
						this.endGame();
					}
					else {
						this.toRecap();
						this.loading = "";
					}
				},
				err => {
					this.err = err.error.detail;
					this.loading = "";
				})
	}

	endGame() {
		this.loading = "Ending Game...";
		this.err = '';
		this.activeModal = "";
		this.gameServe.endGame(this.game.gameid)
			.pipe(finalize(() => this.loading = ""))
			.subscribe(
				(res: EndGameResult) => {
					this.game = new Game(res.game);
					if (this.shift) this.toRecap();
				},
				err => {
					this.err = err.error.detail;
				})
	}

	nextQuestion() {
		this.scoringQuestion++;
		let r_asked = this.game.asked['r_' + this.game.round_index];
		if (this.scoringQuestion >= r_asked.length) {
			this.scoringQuestion = 0;
		}
	}

	previousQuestion() {
		this.scoringQuestion--;
		if (this.scoringQuestion < 0) {
			let r_asked = this.game.asked['r_' + this.game.round_index];
			this.scoringQuestion = r_asked.length - 1;
		}
	}

	calculateAvailableHeight() {
		const screenHeight = window.innerHeight;
		// const navigationBarHeight = window.screen.height - window.innerHeight;
		const availableHeight = screenHeight - 89;

		// console.log("Screen Height:", screenHeight);
		// console.log("Navigation Bar Height:", navigationBarHeight);
		// console.log("Available Height:", availableHeight);

		return availableHeight;

	}

	updateElementHeight() {
		if (this.adjustableDiv) {
			const availableHeight = this.calculateAvailableHeight();
			this.renderer.setStyle(this.adjustableDiv.nativeElement, 'height', `${availableHeight}px`);
		}
	}

  onResize(event: Event) {
    this.updateElementHeight();
  }

}
