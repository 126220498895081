<div class="game-list-item">
	<div class="game-info">
		<h3>{{titleConvert(game.title)}} at {{game.venue}}</h3>
		<p>{{formatDate()}}</p>
		<p>Status: <span [ngClass]="{live: game.status === 'live' || game.status === 'recap missing' || game.status === 'recap needs review', scheduled: game.status === 'scheduled' || game.status === 'recap awaiting approval', warning: game.status === 'recap draft'}">{{game.status.toUpperCase()}}</span></p>
		<p *ngIf="game.hosts && game.hosts.length" class="no-m">
			<strong>Hosted By:</strong>
			<span *ngFor="let h of game.hosts; let i = index" class="md-ml">
				<span *ngIf="i > 0 && game.hosts.length > 2">,</span>
				<span *ngIf="i === game.hosts.length - 1 && game.hosts.length > 1"> and </span>
				<span>{{h.name}}</span>
			</span>
		</p>
	</div>
	<div *ngIf="!loading" class="vert-center">
		<div *ngIf="game.status === 'unconfirmed'" class="vert-center">
			<span class="md-mb center-text">Confirm?</span>
			<div class="hrz-center">
				<button class="basic-btn-red" (click)="confirmShow()">Yes</button>
				<button class="basic-btn-lgt-blue std-ml" (click)="activeModal = 'pleaseContact'">No</button>
			</div>
			<span *ngIf="err" class="center-text error-message">{{err}}</span>
		</div>
		<button *ngIf="game.status !== 'unconfirmed' && !game.gameid" routerLink="/game/{{game.kt_id}}/detail" [queryParams]="game.status === 'scheduled' ? {} : {recaponly: 'true'}" routerLinkActive="active" class="basic-btn-red">View</button>
		<button *ngIf="game.gameid && game.status !== 'approved'" routerLink="/game/{{game.gameid}}/live" routerLinkActive="active" class="basic-btn-red">View</button>
		<button *ngIf="game.gameid && game.status === 'approved'" routerLink="/game/{{game.gameid}}/recap" routerLinkActive="active" class="basic-btn-red">View</button>
	</div>
	<div *ngIf="loading" class="vert-center">
		<span class="center-text">Confirming...</span>
	</div>
</div>

<div *ngIf="activeModal === 'pleaseContact'" class="modal-background">
	<div class="click-layer" (click)="activeModal = ''"></div>
	<div class="modal-container">
		<div class="hrz-between">
			<h2 class="no-mt">Please contact us!</h2>
			<i class="material-icons clear clickable" (click)="activeModal = ''">clear</i>
		</div>
		<p>If you are unable to run your show, please text or call the emergency line ASAP: <strong>805-380-8371</strong></p>
		<div class="hrz-end">
			<button class="basic-btn-lgt-blue" (click)="activeModal = ''">Go Back</button>
		</div>
	</div>
</div>
