<div class="hrz-start answer-box" [ngClass]="classObj | async">
	<div class="d-flex">
		<div *ngIf="chooseAnswer" class="choose-answer">
			<ul (mouseleave)="chooseAnswer = false">
				<li class="cancel" (click)="chooseAnswer = false">Cancel</li>
				<li *ngFor="let a of question.answers; let ai = index" (click)="markCorrect(uv, ai)">{{a.original}}</li>
			</ul>
			<div class="bumper"></div>
		</div>
		<div *ngIf="!viewOnly" class="hrz-start" [ngClass]="updating ? 'score-answer--transparent' : ''">
			<div class="vert-center mark-right clickable"
				(click)="(question.num_answers > 1) ? chooseAnswer = true : startMarkCorrect(uv)">
				<i class="material-icons">done</i>
			</div>
			<div class="vert-center mark-wrong clickable" (click)="markWrong(uv)">
				<i class="material-icons">clear</i>
			</div>
		</div>
		<div class="vert-center w-100">
			<div [ngClass]="viewOnly ? 'hrz-between' : 'hrz-start'">
				<span *ngIf="!uv.value">BLANK</span>
				<span *ngIf="uv.value"><span *ngIf="uv.duplicate">DUPLICATE</span></span>
				<span *ngIf="uv.value">
					<span>{{displayValue}} </span>
					<span *ngIf="uv.original && question.num_answers > 1">({{uv.original}})</span>
				</span>
			</div>
		</div>
		<div *ngIf="updating" class="score-answer--spinner">
			<app-spinner></app-spinner>
		</div>
    <div [title]="uv.count + ' teams have submitted this answer'" class="d-flex"><i class="material-icons">group</i>{{ uv.count }}</div>
	</div>
	<div *ngIf="err !== ''" class="error-message flex">
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
			class="w-6 h-6">
			<path stroke-linecap="round" stroke-linejoin="round"
				d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
		</svg>
		<span>{{ err }}</span>
	</div>
</div>
