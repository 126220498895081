import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Game, FinalScore } from '../classes/Game';
import { Team, Player } from '../classes/Team';
import { CorrectAnswersPipe } from '../correct-answers.pipe';
import { GameService } from '../services/game.service';
import { RecapService } from '../services/recap.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-team-detail',
  templateUrl: './team-detail.component.html',
  styleUrls: ['./team-detail.component.css']
})
export class TeamDetailComponent implements OnInit {

	@Input() team: Team;
	@Input() game: Game;
	@Input() detailIdx: number;
	@Input() teamIdx: number;
	@Input() finalScore: FinalScore;
	@Input() instantUpdateFinalScore: boolean;

	@Output() detail: EventEmitter<any> = new EventEmitter();

	detailType = 'score';
	answerPipe = new CorrectAnswersPipe();

	err: string = "";

	score: number;

	capTarget:number;

	editingFinal: boolean;

	confirmKick: boolean;

	loading: boolean;

	editName: boolean;

	constructor(
		private changeDetector: ChangeDetectorRef,
		private gameServe: GameService,
		private recapServe: RecapService) { }

	ngOnInit(): void {
		if (this.finalScore) {
			this.score = this.finalScore.score;
		}
		else this.score = this.calcScores();
	}

	toggleDetail() {
		this.confirmKick = false;
		if (this.detailIdx === this.teamIdx) {
			this.detail.emit(null);
			console.log("clear the details");
		}
		else this.detail.emit(this.teamIdx);
	}

	renameTeam(new_name: string) {
		this.loading = true;
		this.err = "";
		this.gameServe.editTeamName(this.game.gameid, this.team.team_code, new_name)
			.pipe(finalize(() => this.loading = false))
			.subscribe(
				res => this.editName = false,
				err => this.err = err.error.detail)
	}

	switchTab(tabName: string) {
		this.confirmKick = false;
		this.editName = false;
		this.err = "";
		this.detailType = tabName;
	}

	calcScores() {
		let score = 0;
		for (let r of this.game.question_set.rounds) {
			if (!this.game.answered['r_'+r.round_id]) continue;
			let r_score = 0;
			let r_ans = this.game.answered['r_'+r.round_id]['t_'+this.team.team_code];
			if (r_ans && r_ans.score !== null && r_ans.score !== undefined) {
				score += r_ans.score;
				continue;
			}
			let doubled;
			if (r_ans) {
				doubled = r_ans.doubled;
			}
			let missedOne = false;
			let brkdwn = this.answerPipe.transform(this.game, r.round_id, this.team.team_code);
			if (!brkdwn) continue;
			for (let q of brkdwn) {
				for (let a of q) {
					if (r.double_or_nothing && !a.correct) missedOne = true;
					r_score += a.score;
				}
			}
			if (r.double_or_nothing && doubled) {
				r_score = (missedOne) ? 0 : r_score * 2;
			}
			let overrideScore = this.game.answered['r_'+r.round_id]['t_'+this.team.team_code].score;
			if (overrideScore !== undefined && overrideScore !== null) {
				r_score = overrideScore;
			}
			score += r_score;
		}
		return score;
	}

	setCaptain(p: Player) {
		this.capTarget = null;
		this.gameServe.setCaptain(this.game.gameid, this.team.team_code, p.player_code)
			.subscribe(
				res => {},
				err => { console.log(err); })
	}

	downgradeTeam() {
		this.kickTeam(true);
	}

	removeTeam() {
		this.kickTeam();
	}

	kickTeam(downgrade: boolean = false) {
		this.loading = true;
		this.gameServe.kickTeam(this.game.gameid, this.team.team_code, downgrade)
			.subscribe(
				res => { 
					this.loading = false;
					this.confirmKick = false;
				},
				err => {
					this.loading = false;
					console.log(err);
				});
	}

	teamNumDisplay() {
		if (this.team.kt_team_id < 10000) return "#______";
		else return "#" + this.team.kt_team_id;
	}

	cancelFinalScoreEdit() {
		this.score = this.finalScore ? this.finalScore.score : this.calcScores();
		this.editingFinal = false;
	}

	updateFinalScore(manualOnly: boolean = false) {
		if (this.team.manual || !manualOnly) {
			if (!this.finalScore) {
				this.finalScore = {
					team_id: this.team.kt_team_id.toString(),
					team_name_alternate: this.team.team_name,
					team_code: this.team.team_code,
					score: this.score
				}
				this.game.scores.push(this.finalScore);
			}
			else this.finalScore.score = this.score;
			if (this.instantUpdateFinalScore) {
				this.recapServe.updateFinalScores(this.game.gameid, this.game.scores)
					.subscribe(
						(res: {scores: FinalScore[]}) => {
							this.game.scores = res.scores;
							this.game.sortScores();
						})
			}
			else this.finalScore.pending_change = true;
			this.editingFinal = false;
		}	
	}
	

}
