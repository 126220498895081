<div *ngIf="!loading; else loadMsg" class="std-p">
	<p *ngIf="err; else recapDisplay" class="error-message">{{err}}</p>
</div>

<ng-template #recapDisplay>
	<div class="vert-center items-center">
		<h2 class="no-mb">{{game.venue}}</h2>
		<p class="no-mt">{{game.venue_address}}<br>{{game.venue_city}}, {{game.venue_state}} {{game.venue_zip}}</p>
		<h3>Recap Status: <span class="success-message">{{upperStatus()}}</span></h3>
		<div class="recap-viewer--layout-box">
			<div class="recap-viewer--stat-box">
				<div *ngIf="!shift.no_show" class="game-recap-stats--stat-box">
					<div class="game-recap-stats--times">
						<h5>Shift Start/End Time</h5>
						<app-date-display-edit 
							[origDate]="startWork()" 
							[state]="'view'"></app-date-display-edit> - <br class="date-breaker">
						<app-date-display-edit 
							[origDate]="endWork()"
							[state]="'view'"></app-date-display-edit>
					</div>
					<div class="game-recap-stats--times">
						<h5>Game Start/End Time</h5>
						<app-date-display-edit 
							[origDate]="shift.game_start ? shift.game_start : game.first_action" 
							[state]="'view'"></app-date-display-edit> - <br class="date-breaker">
						<app-date-display-edit 
							[origDate]="shift.game_end ? shift.game_end : game.last_action"
							[state]="'view'"></app-date-display-edit>
					</div>
					<div class="game-recap-stats--players">
						<h5>Player Count</h5>
						<span>{{shift.total_players}}</span>
					</div>
					<div *ngIf="!game.use_external_app" class="game-recap-stats--teams">
						<h5>Team Count</h5>
						<span>{{shift.qm_teams}}</span>
					</div>
					<div class="game-recap-stats--tables">
						<h5>Empty Tables</h5>
						<span>{{shift.empty_tables}}</span>
					</div>
					<div class="game-recap-stats--rating">
						<h5>Question Rating</h5>
						<span>{{shift.question_rating}}</span>
					</div>
				</div>
				<div class="recap-viewer--notes">
					<h5>Internal Notes</h5>
					<p>{{shift.internal_notes}}</p>
				</div>
			</div>
			<div *ngIf="!shift.no_show" class="vert-center items-center">
				<app-image-carousel [images]="game.images"></app-image-carousel>
				<p [innerHTML]="shift.recap | safeHtml"></p>
				<div *ngIf="!game.use_external_app" class="w-100">
					<div class="game-recap-public--header">
						<div class="game-recap-public--rank">Rank</div>
						<div class="game-recap-public--team-id">Team ID</div>
						<div class="game-recap-public--team-name">Team Name</div>
						<div class="game-recap-public--score">Score</div>
					</div>
					<div class="game-recap-public--row" *ngFor="let t of game.scores; let i = index">
						<div class="game-recap-public--rank">{{t.rank_order}}</div>
						<div class="game-recap-public--team-id">{{t.team_id}}</div>
						<div class="game-recap-public--team-name">{{t.team_name_alternate}}</div>
						<div class="game-recap-public--score">{{t.score}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #loadMsg>
	<h1 class="center-text">Loading...</h1>
</ng-template>
