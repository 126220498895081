import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { GameService } from '../services/game.service';
import { AuthService } from '../services/auth.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-recap-viewer',
  templateUrl: './recap-viewer.component.html',
  styleUrls: ['./recap-viewer.component.css']
})
export class RecapViewerComponent implements OnInit {

  game: FinalizedGame;
  shift: Shift;
  userid: string;

  loading = true;

  err = "";

  constructor(
    private gameServe: GameService, 
    private route: ActivatedRoute,
    private auth: AuthService) { }

  ngOnInit(): void {
    this.auth.getHostDetail().subscribe((res) => {
      this.userid = res.userid
    })
    this.route.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');
      this.getGame(id)
    })
  }

  getGame(gameid: string) {
    this.gameServe.getGameRecap(gameid)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        (res: ApprovedRecap) => {
          this.game = res.game;
          console.log(res.shifts);
          for (let s of res.shifts) {
            if (s.user.userid === this.userid) {
              this.shift = s;
              break;
            }
            if (!this.shift) this.err = "No recap found for your user on this game.";
          }
        },
        err => this.err = err.error.detail)
  }

  upperStatus() {
    return this.shift.status.toUpperCase();
  }

  startWork() {
    let start = "";
    for (let w of this.shift.work) {
      if (w.start_work > start) start = w.start_work;
    }
    return start;
  }

  endWork() {
    let end = "";
    for (let w of this.shift.work) {
      if (w.end_work > end) end = w.end_work;
    }
    return end;
  }

}

type FinalizedGame = {
  venue: string,
  title: string,
  venue_address: string,
  venue_city: string,
  venue_state: string,
  venue_zip: string,
  venue_tz: string,
  private_event: boolean,
  images: GameImage[],
  scores: FinalScore[],
  use_external_app: boolean,
  first_action: string,
  last_action: string
}

type ApprovedRecap = {
  game: FinalizedGame,
  shifts: Shift[]
}
