import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Game } from '../classes/Game';
import { User } from '../classes/User';
import { RecapService } from '../services/recap.service';
import { ShiftService } from '../services/shift.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-game-recap-public',
  templateUrl: './game-recap-public.component.html',
  styleUrls: ['./game-recap-public.component.css']
})
export class GameRecapPublicComponent implements OnInit {

  @Input() game: Game;
  @Input() user: User;
  @Input() shift: Shift;
  @Input() editing: boolean;
  @Output() cancelEdit: EventEmitter<boolean> = new EventEmitter();
  @Output() doneEditing: EventEmitter<boolean> = new EventEmitter();

  images: GameImage[] = [];

  approved: GameImage[] = [];

  recap: ShiftPublic = { recap: "" };

  scoreBackups: FinalScore[] = [];

  err = "";
  loading = false;

  imageLoading = true;

  busyUpload: boolean;

  tDisplay: { rank?: number, kt_team_id: number, team_name: string, score: number }[] = []

  constructor(
    private recapServe: RecapService,
    private shiftServe: ShiftService
  ) { }

  ngOnInit(): void {
    this.getGameImages();
    this.backupScores();
    this.sortTeamsByScore();
    this.recap.recap = this.shift.recap;
  }

  cancel() {
    this.cancelEdit.emit(true);
    this.getGameImages();
    this.restoreScores();
    this.recap.recap = this.shift.recap;
  }

  backupScores() {
    this.scoreBackups = [];
    if (this.game.scores) {
      for (let s of this.game.scores) {
        this.scoreBackups.push({
          team_id: s.team_id,
          team_name_alternate: s.team_name_alternate,
          score: s.score,
          team_code: s.team_code
        })
      }
    }
  }

  restoreScores() {
    this.game.scores = [];
    for (let s of this.scoreBackups) {
      this.game.scores.push({
        team_id: s.team_id,
        team_name_alternate: s.team_name_alternate,
        score: s.score,
        team_code: s.team_code
      })
    }
  }

  updateScore(score: FinalScore) {
    for (let s of this.game.scores) {
      if (s.team_code === score.team_code) {
        s.score = score.score;
        s.pending_change = true;
      }
    }
  }

  setApproved() {
    let imgs: GameImage[] = [];
    for (let i of this.images) {
      if (i.status === 'approved') {
        imgs.push(i);
      }
    }
    imgs.sort((a: GameImage, b: GameImage) => {
      if (a.position === undefined) {
        return b.position >= 0 ? 1 : 0;
      }
      else if (b.position === undefined) {
        return b.position >= 0 ? -1 : 0;
      }
      if (a.position > b.position) return 1;
      else if (a.position < b.position) return -1;
      else return 0;
    })
    this.approved = imgs;
  }

  sortTeamsByScore() {
    let tms = [];
    for (let t of this.game.teams) {
      let s = this.game.teamScore(t.team_code);
      tms.push({
        kt_team_id: t.kt_team_id,
        team_name: t.team_name,
        score: s
      });
    }
    tms.sort((a, b) => {
      if (a.score > b.score) return -1;
      else if (a.score < b.score) return 1;
      else return 0;
    });
    let cr = 1;
    let ls;
    for (let i = 0; i < tms.length; i++) {
      if (ls !== undefined) {
        if (tms[i].score !== ls) {
          ls = tms[i].score;
          cr = i + 1;
        }
      }
      else ls = tms[i].score;
      tms[i].rank = cr;
    }
    this.tDisplay = tms;
  }

  async verify() {
    let msgs: string[] = [];
    if (this.game.status === "ended") {

      let mustVerify = true;
      let shifts: Shift[] = await this.shiftServe.getGameShifts(this.game.gameid).toPromise();
      let otherShifts = shifts.filter(shift => shift.shiftid !== this.shift.shiftid);
      let approvedShifts = otherShifts.filter(shift => shift.status === "approved");

      // If there are more than one shift and not all other shifts are approved, skip
      if (otherShifts.length > 0 && approvedShifts.length !== otherShifts.length) {
        mustVerify = false;
      }

      // If there is only one shift or all others are approved, must verify
      if (otherShifts.length === 0 || approvedShifts.length === otherShifts.length) {
        mustVerify = true;
      }

      if (mustVerify) {
        if (!this.approved.length) {
          msgs.push("Cannot publish without at least one photo");
        }
        if (!this.game.use_external_app && !this.game.scores?.length) {
          msgs.push("Missing scores. Please enter scores or mark 'Show did not happen'.");
        }
      }


    }
    return msgs;
  }

  getGameImages() {
    this.imageLoading = true;
    this.recapServe.getGameImages(this.game.gameid)
      .pipe(finalize(() => this.imageLoading = false))
      .subscribe(
        (res: GameImage[]) => this.setImages(res),
        err => this.err = err.error.detail)
  }

  setImages(images: GameImage[]) {
    this.images = images;
    this.setApproved();
  }

  addImages(imgs: GameImage[]) {
    for (let i of imgs) {
      this.images.push(i);
    }
    this.setApproved();
  }

  saveRecap() {
    this.loading = true
    this.err = "";
    this.shiftServe.editRecap(this.shift.shiftid, this.recap.recap)
      .subscribe(
        (res: Shift) => {
          this.shift.recap = this.recap.recap;
          this.updateFinalScores();
        },
        err => {
          this.err = err.error.detail;
          this.loading = false;
        })
  }

  updateFinalScores() {
    let toUpdate = [];
    if (this.game.scores) {
      for (let sc of this.game.scores) {
        if (sc.pending_change) toUpdate.push(sc);
      }
    }
    if (toUpdate.length) {
      this.recapServe.updateFinalScores(this.game.gameid, toUpdate)
        .subscribe(
          (res: { scores: FinalScore[] }) => {
            this.game.scores = res.scores;
            this.game.sortScores();
            this.backupScores();
            this.sortTeamsByScore();
            this.checkImagesForUpdate();
          },
          err => {
            this.err = err.error.detail;
            this.loading = false;
          })
    }
    else this.checkImagesForUpdate();
  }

  checkImagesForUpdate() {
    let toUpdate = [];
    for (let im of this.images) {
      if (im.pending_change) toUpdate.push(im);
    }
    this.updateImages(toUpdate);
  }

  updateImages(toUpdate: GameImage[]) {
    if (toUpdate.length) {
      let img = toUpdate.shift();
      if (img.status === 'blocked') {
        this.updateImages(toUpdate);
        return;
      }
      let approve = img.status === 'approved' ? true : false;
      this.recapServe.setImageApproval(
        this.game.gameid,
        img.image_code,
        approve,
        img.position)
        .subscribe(
          (res: GameImage[]) => {
            if (!toUpdate.length) {
              this.setImages(res);
              this.doneEditing.emit(true);
              this.loading = false;
            }
            else this.updateImages(toUpdate);
          },
          err => {
            this.err = err.error.detail;
            this.loading = false;
          })
    }
    else {
      this.doneEditing.emit(true);
      this.loading = false;
    }
  }

}
