<div class="team-line">
	<div class="pos-rel">
		<div class="collapse-cover" (click)="collapse = !collapse"></div>
		<div class="hrz-between clickable">
			<div class="hrz-between md-py name-box">
        <div class="left-text md-px">
          <strong class="text-grey">{{ team.rank }}</strong>
        </div>
				<div class="sm-pl t-name">
					<span>{{team.kt_team_id ? team.kt_team_id : '______'}} - {{team.team_name}}</span>
				</div>
				<div class="center-text submit-box">
					<i *ngIf="submit" class="material-icons success-message">done</i>
				</div>
			</div>
			<div class="hrz-between md-py score-box">
				<div class="w-50">
					<div *ngIf="!round.tiebreaker">
						<span *ngIf="editingScore || team.manual" class="sm-ml">
							<input class="score-edit" type="number" [(ngModel)]="newScore" (focusout)="startManualScoreEdit()">
						</span>
						<span *ngIf="!editingScore && !team.manual" class="std-pl" (click)="editingScore = true">
							<i class="material-icons edit-icon">create</i>
							<span *ngIf="doubled">*</span>
							<span>{{displayScore}}</span>
						</span>
						<div *ngIf="editingScore && !team.manual" class="edit-ctrl hrz-between">
							<i class="material-icons" (click)="editingScore = false">clear</i>
							<i class="material-icons" (click)="saveScore()">done</i>
						</div>
					</div>
				</div>
				<div class="w-50 center-text">
					<strong>{{totalScore}}</strong>
				</div>
			</div>
		</div>
	</div>
	<ng-container *ngTemplateOutlet="scoreBreakdown"></ng-container>
</div>



<div class="team-box sm-p sm-mb">
	<div class="pos-rel">
		<div class="collapse-cover" (click)="collapse = !collapse"></div>
		<div class="t-box-content">
			<div class="vert-center md-mr">
				<strong class="text-grey">{{ team.rank }}</strong>
			</div>
			<div class="t-box-detail">
				<div>{{team.kt_team_id ? team.kt_team_id : '______'}}</div>
				<div>{{team.team_name}}</div>
				<div [ngClass]="(submit) ? 'submitted' : ''">{{submit ? 'SUBMITTED' : 'NOT SUBMITTED'}}</div>
			</div>
			<div class="hrz-end">
				<div class="vert-center">
					<span *ngIf="!editingScore && !team.manual" (click)="editingScore = true">
						<i class="material-icons edit-icon">create</i>
						<span *ngIf="doubled">*</span>
						<span>{{displayScore}}</span>
					</span>
					<span *ngIf="editingScore || team.manual">
						<input class="score-edit" type="number" [(ngModel)]="newScore" (focusout)="startManualScoreEdit()">
					</span>
				</div>
				<div class="mobile-total vert-center">
					<strong>{{totalScore}}</strong>
				</div>
			</div>
		</div>
		<div *ngIf="editingScore && !team.manual" class="hrz-end mobile-bumper">
			<div class="edit-ctrl hrz-between">
				<i class="material-icons" (click)="editingScore = false">clear</i>
				<i class="material-icons" (click)="saveScore()">done</i>
			</div>
		</div>
	</div>
	<ng-container *ngTemplateOutlet="scoreBreakdown"></ng-container>
</div>



<ng-template #scoreBreakdown>
	<div *ngIf="!collapse" class="more-info">
		<ul *ngIf="breakdown; else noSub" class="breakdown-box">
			<li class="md-my">
				<button *ngIf="!resetPending" class="basic-btn-red" (click)="resetPending = true">Reset Submission</button>
				<span *ngIf="resetPending">
					<span>Really reset?</span>
					<button class="std-ml basic-btn-red" (click)="resetSubmission()">Yes</button>
					<button class="std-ml basic-btn-lgt-blue" (click)="resetPending = false">No</button>
				</span>
			</li>
			<li>
				<strong>Submitted Answers:</strong>
			</li>
			<li *ngFor="let q of breakdown; let i = index" class="hrz-between">
				<div class="hrz-start">
					<div>Q{{i + 1}}.  </div>
					<div class="md-ml">
						<div *ngFor="let a of q">{{a.value ? a.value : '--'}}</div>
					</div>
				</div>
				<div>
					<div *ngFor="let a of q" class="hrz-between">{{a.score}}</div>
				</div>
			</li>
			<li *ngIf="doubled" class="hrz-between">
				<div></div>
				<div>Double or Nothing: {{doubleModifier()}}</div>
			</li>
			<li *ngIf="overrideScore !== null && overrideScore !== undefined" class="hrz-between">
				<div></div>
				<div>QM Edit: {{(overrideScore - roundScore > 0) ? '+' : ''}}{{overrideScore - roundScore}}</div>
			</li>
		</ul>
		<ng-template #noSub>
			<p class="center-text no-m md-pb">No answers submitted yet...</p>
		</ng-template>
		<p class="no-m md-py center-text clickable" (click)="collapse = true">
			<i class="material-icons">expand_less</i>
		</p>
	</div>
</ng-template>
