<div class="hrz-between lg-text md-pb">
  <div class="rank-padder"></div>
  <!-- <div class="vert-center w-50 left-text">
    <h4 class="no-mb"><span>Rank</span></h4>
  </div> -->
	<div class="vert-center w-100">
		<h4 class="no-mb clickable" (click)="teamSort()">
			<span>Team</span>
			<i *ngIf="sortby === 'teams' && !asc" class="material-icons vert-middle-text">arrow_drop_down</i>
			<i *ngIf="sortby === 'teams' && asc" class="material-icons vert-middle-text">arrow_drop_up</i>
		</h4>
	</div>
	<div class="vert-center w-100">
		<h4 class="no-mb right-text clickable" (click)="scoreSort()">
			<span>Score</span>
			<i *ngIf="sortby === 'score' && !asc" class="material-icons vert-middle-text">arrow_drop_down</i>
			<i *ngIf="sortby === 'score' && asc" class="material-icons vert-middle-text">arrow_drop_up</i>
		</h4>
	</div>
</div>
<app-team-detail *ngFor="let t of game.teams; let i = index" [team]="t" [game]="game" [detailIdx]="teamDetailIdx" [teamIdx]="i" [finalScore]="game.getTeamFinalScore(t.team_code)" [instantUpdateFinalScore]="instantUpdateFinalScore" (detail)="teamDetailIdx = $event"></app-team-detail>
<div *ngIf="!newTeam" class="hrz-center lg-pt center-text tbl-brd-t">
	<span (click)="startNewTeam()" class="clickable">
		<i class="material-icons sm-mr v-align-mid">group_add</i>
		<span>Add a team</span>
	</span>
</div>
<div *ngIf="newTeam && !addingTeam">
	<h3 class="center-text no-mb">Manually Add a Team</h3>
	<div class="hrz-center">
		<p class="no-mt center-text w-max-300">This feature is intended ONLY for adding a team who elects not to use the app.</p>
	</div>

	<div class="hrz-center">
		<div class="w-100 w-max-300">
			<label>Team Name</label>
			<input class="team-input" type="text" [(ngModel)]="newTeam.team_name">
		</div>
	</div>

	<div class="hrz-center std-mt">
		<div class="w-100 w-max-300">
			<label>Team Number (only if provided)</label>
			<input class="team-input" type="number" [(ngModel)]="newTeam.kt_team_id">
		</div>
	</div>

	<div class="hrz-center std-my">
		<div class="w-100 w-max-300 hrz-between">
			<button class="basic-btn-grey" (click)="newTeam = null">Cancel</button>
			<button class="basic-btn-red" [disabled]="!newTeam.team_name" (click)="addTeam()">Add Team</button>
		</div>
	</div>
</div>
