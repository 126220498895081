<h2 *ngIf="loading" class="loading-message">Finding Your Games...</h2>

<h2 *ngIf="err" class="error-message">{{err}}</h2>

<div *ngIf="!loading" class="routed-container">
	<div class="routed-content">
		<div class="game-title-header">
			<h1 class="title-message">Your Games</h1>
			<button class="basic-btn-grey" (click)="getGameList(true)">
				<i class="material-icons no-m">sync</i>
				<span>Check for Updates</span>
			</button>
		</div>
		<p class="game-list-header">Live and Upcoming Games</p>
		<app-game-stub *ngFor="let g of games" [game]="g"></app-game-stub>
		<p class="game-list-header">Past Games</p>
		<app-game-stub *ngFor="let p of pastGames" [game]="p"></app-game-stub>
		<app-game-stub *ngFor="let p of approvedGames" [game]="p"></app-game-stub>
    <div class="hrz-center std-py w-100">
      <button *ngIf="!endOfPage" class="basic-btn-grey " (click)="getMoreGames()" [disabled]="fetchingMore">
        {{ fetchingMore ? 'Retrieving...':'Show More' }}
      </button>
      <p *ngIf="endOfPage">You're all caught up!</p>
    </div>
    </div>
</div>