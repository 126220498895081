<!-- <h4 *ngIf="game.submissions_open" class="center-text">
	<span>Waiting on {{game.remainingSubmits(round.round_id)}} team(s) to submit</span>
</h4> -->

<div class="hrz-between sort-header">
	<div class="vert-end team-info-header">
		<div class="hrz-between">
            <!-- <div class="sm-p hrz-start clickable total-head">
				<span>Rank</span>
			</div> -->
			<div class="sm-p clickable w-50" (click)="sortBy('teamName')">
				<span>Team Name</span>
				<i *ngIf="sortType === 'teamName'" class="material-icons">
					<span *ngIf="!asc">arrow_drop_down</span>
					<span *ngIf="asc">arrow_drop_up</span>
				</i>
			</div>
			<div class="sm-p clickable hrz-start submit-head" (click)="sortBy('submitted')">
				<span>Submit</span>
				<i *ngIf="sortType === 'submitted'" class="material-icons">
					<span *ngIf="!asc">arrow_drop_down</span>
					<span *ngIf="asc">arrow_drop_up</span>
				</i>
			</div>
		</div>
	</div>
	<div class="vert-end score-header">
		<div class="center-text scores-top">Scores</div>
		<div class="hrz-between scores-bottom">
			<div class="sm-p hrz-start clickable" (click)="sortBy('roundScore')">
				<span>Round</span>
				<i *ngIf="sortType === 'roundScore'" class="material-icons">
					<span *ngIf="!asc">arrow_drop_down</span>
					<span *ngIf="asc">arrow_drop_up</span>
				</i>
			</div>
			<div class="sm-p hrz-start clickable total-head" (click)="sortBy('totalScore')">
				<span>Total</span>
				<i *ngIf="sortType === 'totalScore'" class="material-icons">
					<span *ngIf="!asc">arrow_drop_down</span>
					<span *ngIf="asc">arrow_drop_up</span>
				</i>
			</div>
		</div>
	</div>
</div>

<div class="hrz-start mobile-sort-header md-my">
	<div class="hrz-start">
		<div class="vert-center">
			<span>Sort By:</span>
		</div>
		<select [(ngModel)]="sortType" class="sm-ml">
			<option value="teamName">Team Name</option>
			<option value="submitted">Submitted</option>
			<option value="roundScore">Round Score</option>
			<option value="totalScore">Total Score</option>
		</select>
	</div>
	<div class="asc-dsc clickable md-ml" (click)="asc = !asc">
		<i *ngIf="asc" class="material-icons">arrow_upward</i>
		<i *ngIf="!asc" class="material-icons">arrow_downward</i>
	</div>
</div>

<app-round-team-summary
	*ngFor="let t of game.teams | teamsForRoundSummary:game:round:sortType:asc:game.resetter"
	[game]="game"
	[round]="round"
	[team]="t.team"
	[submit]="t.submitted"
	[roundScore]="t.roundScore"
	[totalScore]="t.totalScore"
	[doubled]="t.doubled"
	[breakdown]="t.breakdown"
	[overrideScore]="t.overrideScore"
	(reset)="passReset($event)"></app-round-team-summary>
