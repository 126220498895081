import { Component, OnInit } from '@angular/core';
import { GameStub } from '../classes/Game';
import { GameService } from '../services/game.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.css']
})
export class GameListComponent implements OnInit {

	games: GameStub[] = [];
	pastGames: GameStub[] = [];
	approvedGames: GameStub[] = [];

	loading = true;
	err = "";

  // game listing
  fetchingMore: boolean = false
  endOfPage: boolean = false
  limit: number = 10
  offset: number = 0
  gdate: string;

	constructor(private gameServe: GameService, private router: Router) { }

	ngOnInit(): void {
		this.getGameList();
	}

  getGameList(refresh_cache: boolean = false) {
		this.loading = true;
		this.err = "";
		let obs = this.gameServe.getGameList()
			.pipe(finalize(() => this.loading = false))
			.subscribe((res: {games: GameStub[], gdate: string}) => {
				this.sortGames(res.games);
				this.gdate = res.gdate;
			},
			err => {
				this.err = "An error occurred. If problem persists, contact your site administrator";
			})
	}

  getMoreGames() {
    this.fetchingMore = true;
    this.gameServe.getMoreGameList(this.limit, this.offset, this.gdate)
      .pipe(finalize(() => {
      	this.loading = false;
      	this.fetchingMore = false;
      }))
      .subscribe((res: {games: GameStub[], gdate: string}) => {
	        if (res.games.length === 0) {
	          this.endOfPage = true;
	        }
	        else {
	        	for (let g of res.games) {
	        		this.approvedGames.push(g);
	        		this.offset = this.offset + this.limit;
	        	}
	        }
	      },
	      err => {
	        this.err = "An error occurred. If problem persists, contact your site administrator";
	      })
  }

	sortGames(games: GameStub[]) {
		this.pastGames = [];
		this.games = [];
		let dtPrts = new Date().toLocaleString().split('/');
		let tMonth = parseInt(dtPrts[0]) < 10 ? `0${dtPrts[0]}` : dtPrts[0];
		let tDay = parseInt(dtPrts[1]) < 10 ? `0${dtPrts[1]}` : dtPrts[1];
		let utc_today = `${dtPrts[2].substring(0,4)}-${tMonth}-${tDay}`;
		for (let g of games) {
			let d = new Date(g.date).toLocaleString();
			let gdPrts = d.split('/');
			let utc_game = g.date;
			if (gdPrts.length >= 3) {
				let month = parseInt(gdPrts[0]) < 10 ? `0${gdPrts[0]}` : gdPrts[0];
				let day = parseInt(gdPrts[1]) < 10 ? `0${gdPrts[1]}` : gdPrts[1];
				utc_game = `${gdPrts[2].substring(0,4)}-${month}-${day}`;
			}
      if (utc_game >= utc_today) {
				this.games.push(g);
      }
			else if (g.status == "live") this.games.push(g);
			else {
				if (g.status == "scheduled") g.status = "missing";
				if (g.status == "submitted") g.status = "awaiting approval";
				if (g.status == "review") g.status = "needs review";
				g.status = `recap ${g.status}`;
				this.pastGames.push(g);
			}
			g.display_date = d;
		}
		this.games.sort((a, b) => {
			if (a.date > b.date) return -1;
			if (a.date < b.date) return 1;
			return 0;
		});
		this.pastGames.sort((a, b) => {
			if (a.date > b.date) return -1;
			if (a.date < b.date) return 1;
			return 0;
		});
	}

	titleConvert(title: string) {
		if (title === 'trivia' || title === 'slam') {
			return (title === 'trivia') ? 'Pub Quiz' : 'Survey Slam';
		}
		return title;
	}

	viewGame(game_id: number) {
		this.router.navigate(['game', game_id, '/detail']);
	}

}
